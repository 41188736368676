import React, { useEffect, useState } from "react";
import { useMedia } from "use-media";
import Layout from "../../components/chat-channel/Layout";
import RegisterSection from "../../components/chat-channel/UsecaesHeader";
import Container from "../../components/chat-channel/Container";
import AppsIntegrations from "../../components/common/usecases/appsIntegrations";
import ArrowRightICon from "../../components/common/Icons";
import AnchorLink from "react-anchor-link-smooth-scroll";
import LeftFormRightImage from "../../components/chat-channel/LeftFormRightImage";
import KeyResources from "../../components/common/KeyResources";
import Cards from "../../components/common/knowledge/Cards";

import { OnScrollPopup } from "../conversational-ai-platform/hr-chatbot";
import TitleAndMetaTags from "../../components/common/TitleAndHeader";
import { RequestForm } from "../../components/form";
import { Slider } from "../../components/common/sliderHomePage";
import {GoToPopup} from "../homepage"

export const blogImg = require("../../assets/img/key-resources/bc_1_1.png");
export const whitepaperImg = require("../../assets/img/key-resources/wp_1_1.png");
export const videoImg = require("../../assets/img/key-resources/vc_1_1.png");

const section_1 = require("../../assets/images/usecases/desktop/usecase_icon_5.png");
const section_3 = require("../../assets/images/usecases/desktop/usecase_icon_1.png");
const section_4 = require("../../assets/images/usecases/desktop/usecase_icon_2.png");

const section_1_mob = require("../../assets/images/usecases/mobile/usecase_icon_5_mob.png");
const section_3_mob = require("../../assets/images/usecases/mobile/usecase_icon_1_mob.png");
const section_4_mob = require("../../assets/images/usecases/mobile/usecase_icon_2_mob.png");

const slackteam = require("../../assets/images/usecases/offboarding/employee_offboarding_last.png");
const automation_steps = require("../../assets/images/usecases/offboarding/offboard_steps.png");
const mob_onboard_steps = require("../../assets/images/usecases/mobile/mob_offboard_steps.png");

const aboveFoldImage = require("../../assets/images/usecases/offboarding/employee_offboarding_header.png");

const resoucesData = [
  {
    image: blogImg,
    header:
      "Reinventing the role of HR during the COVID-19 Pandemic CTA: Read Blog",
    url:
      "https://blog.workativ.com/reinventing-the-role-of-hr-during-the-covid-19-pandemic-business-continuity-planning-chatbots-and-whatnot/",
    link: "Read Blog",
    alt: "Employee Offboarding Automation"
  },
  {
    image: whitepaperImg,
    header:
      "What’s the real value for business in transforming workplace support?  ",
    url: "/conversational-ai-platform/chatbot-whitepaper",
    link: "Read Whitepaper",
    alt: "Employee Offboarding Whitepaper"
  },
  {
    image: videoImg,
    header: " How to create an intelligent Helpdesk Chatbot with Zero coding ",
    url: "https://youtu.be/uMqbQ3A4h4E",
    link: "Watch Video",
    alt: "Employee Offboarding Video"
  }
];
export const cardData = [
  {
    href: null,
    header: "Problem",
    content:
      "Not having a streamlined, automated process for offboarding can lead to security risks to the company’s network post an employee’s exit. ",
    backgroundClass: "card_one"
  },
  {
    href: null,
    header: "Solution",
    content:
      "Workativ integrates with popular IT and HR applications, and with AI and Automation, enables teams to complete the offboarding process faster. ",
    backgroundClass: "card_two"
  },
  {
    href: null,
    header: "Impact",
    content:
      "Having a systematic and automated process for offboarding ensures that employees get removed from the company’s network before exit. ",
    backgroundClass: "card_three"
  }
];

const headerMenu = [
  {
    menu: "Overview",
    href: "#section_1",
    id: "section_1"
  },
  {
    menu: "Why digital offboarding",
    href: "#section_2",
    id: "section_2"
  },
  {
    menu: "Offboarding automation steps",
    href: "#section_3",
    id: "section_3"
  },
  {
    menu: "Offboarding chatbot on Slack and Teams",
    href: "#section_4",
    id: "section_4"
  },
  {
    menu: " Other popular use cases",
    href: "#section_5",
    id: "section_3"
  }
];

const sectionImg = [
  {
    img: section_1,
    mobImage: section_1_mob,
    alt: "Employee Offboarding",
    header: "Employee Offboarding",
    paragraph:
      "Ex-employees accessing corporate apps can be detrimental to organizations. Workativ provides you with an automation platform to create your Offboarding Automation workflows. With Workativ, you can simplify the process of employee offboarding tasks for HR and IT Teams without compromising security and ensuring that the process is complete and safe to say goodbye to employees without any oversight. "
  },
  {
    img: section_4,
    mobImage: section_4_mob,
    alt: "Offboarding Automation",
    header: "Offboarding Automation",
    paragraph:
      "Get a jumpstart to automating the offboarding process with Workativ’s pre-built automations. Workativ’s powerful automation platform and integration with various HR, IT, Access management apps Like Okta, Office 365 and ServiceNow, enables connecting your existing apps, creating workflows, and also completing the offboarding tasks with an AI-powered chatbot.      "
  },
  {
    img: section_3,
    mobImage: section_3_mob,
    alt: "Digital Offboarding",
    header: "Digital Offboarding",
    paragraph:
      "Workativ enables digital offboarding with an AI-powered chatbot to help HR teams save time. Digital offboarding allows you to complete the exit process or offboarding process for multiple employees at a time. With no manual intervention required, the digital offboarding process is faster and also ensures systematic steps to ensure proper employee exits from all your systems. "
  }
];

export default function HomePage() {
  const [isOpen, setOpen] = React.useState(false);

  const [isActive, setIsActive] = React.useState("Overview");

  const [header, setHeader] = useState("top_menu_sticky");

  const [dropdown, setDropdown] = useState("Overview");

  const listenScrollEvent = e => {
    if (window.scrollY < 73) {
      return setHeader("top_menu_sticky");
    } else if (window.scrollY > 70) {
      return setHeader("top_menu_sticky top_menu_sticky_fixed");
    }
  };

  useEffect(() => {
    window.addEventListener("scroll", listenScrollEvent);

    return () => window.removeEventListener("scroll", listenScrollEvent);
  }, []);
  const isSmall = useMedia({ maxWidth: "520px" });

  return (
    <>
      <TitleAndMetaTags
        title="Employee Offboarding Automation - HR workflow automation software - Workativ "
        description="Automate Employee Offboarding process for modern HR and IT Teams. Deploy Offboarding automation chatbot on Slack and Microsoft Teams now."
        keywords={["IT Helpdesk Chatbot", "HR Chatbot", "Service Desk Chatbot"]}
        ogImage={slackteam}
        ogTitle="Employee Offboarding Automation - HR workflow automation software - Workativ "
        ogDescription="Automate Employee Offboarding process for modern HR and IT Teams. Deploy Offboarding automation chatbot on Slack and Microsoft Teams now."
      />
      <Container>
      <Layout logoFor="ASSISTANT">
          <section className={header}>
            {/* <div className="container-fluid bg_apps_usecaes">
              <div className="row">
                <div className="container">
                  <div className="sticky_menu">
                    <span>Employee Offboarding:</span>
                    <ul>
                      {headerMenu.map(menu => (
                        <li>
                          <AnchorLink
                            offset={() => 160}
                            href={menu.href}
                            className={
                              isActive === menu.menu
                                ? "url_manipulation active"
                                : "url_manipulation "
                            }
                            onClick={() => {
                              setIsActive(menu.menu);
                            }}
                          >
                            {" "}
                            {menu.menu}
                          </AnchorLink>
                        </li>
                      ))}
                    </ul>
                  </div>
                </div>
              </div>
            </div> */}

            <RegisterSection
              backgroundColor={"bg_apps_usecaes "}
              rightImage={aboveFoldImage}
              altImage={"Employee Offboarding Automation"}
              videoToken={"__NO_VIDEO_AVAILABLE__"}
              headerMenu={headerMenu}
            >
              <RegisterSection.FormWithContent>
                <RegisterSection.MainHeader>
                  Employee Offboarding Automation
                </RegisterSection.MainHeader>
                <RegisterSection.SubHeader>
                  Automate the employee offboarding process and remove the risk
                  of ex-employees’ access to your systems and network.
                </RegisterSection.SubHeader>
              </RegisterSection.FormWithContent>
            </RegisterSection>

            {/* {isSmall ? null : <OnScrollPopup />} */}

            <div className="mob_sticky_wrapper">
              {/* <div className="dropdown wv-dropdown">
                <h4>Employee Offboarding:</h4>
                <button
                  className={`link ${isOpen ? "active" : ""}`}
                  onClick={() => setOpen(!isOpen)}
                >
                  {dropdown}
                </button>
                {headerMenu.map(menu => (
                  <div
                    onClick={() => setOpen(!isOpen)}
                    id="myDropdown"
                    className={`accordion-item submenu dropdown-content ${
                      !isOpen ? "collapsed" : ""
                    }`}
                  >
                    {" "}
                    <li className={menu.id}>
                      <AnchorLink
                        offset={460}
                        className="url_manipulation"
                        href={menu.href}
                        onClick={() => setDropdown(menu.menu)}
                      >
                        {menu.menu}
                      </AnchorLink>
                    </li>
                  </div>
                ))}
              </div> */}
              <AppsIntegrations
                header={
                  "Employee Offboarding made easy with your favorite apps"
                }
                cards={appsName}
                id={"section_1"}
              />
              <section className="boarding_wrapper">
                <div className="container">
                  <div className="row">
                    {sectionImg.map((card, index) => {
                      if (index % 2 == 0) {
                        return (
                          <div className="boarding_wrapper_container">
                            <div className="row">
                              <div className="col-md-12 col-lg-3 col-12">
                                <picture>
                                  <source
                                    media="(max-width:768px)"
                                    srcSet={card.mobImage}
                                  />
                                  <img loading = "lazy" src={card.img} alt={card.alt}></img>
                                </picture>
                              </div>
                              <div className="col-md-12 col-lg-9 col-12 pad_left_30">
                                <h4>{card.header}</h4>
                                <p>{card.paragraph} </p>
                              </div>
                            </div>
                          </div>
                        );
                      }
                      return (
                        <div className="boarding_wrapper_container">
                          <div className="row">
                            <div className="col-md-12 col-lg-9 col-12 pad_right_30">
                              <h4>{card.header}</h4>
                              <p>{card.paragraph} </p>
                            </div>
                            <div className="col-md-12 col-lg-3 col-12">
                              <picture>
                                <source
                                  media="(max-width:768px)"
                                  srcSet={card.mobImage}
                                />
                                <img loading = "lazy" src={card.img} alt={card.alt}></img>
                              </picture>
                            </div>
                          </div>
                        </div>
                      );
                    })}
                  </div>
                </div>
              </section>

              <Cards
                id={"section_2"}
                cards={cardData}
                header={"Why digital offboarding"}
              />
              <section className="automation_steps" id={"section_3"}>
                <div className="container">
                  <h2>How Employee Offboarding automation works</h2>

                  <picture>
                    <source
                      media="(max-width:768px)"
                      srcSet={mob_onboard_steps}
                    />
                    <img loading = "lazy"
                      src={automation_steps}
                      alt="How Employee Offboarding automation works"
                    />
                  </picture>
                </div>
              </section>
              <section className="slack_teams_wrapper" id={"section_4"}>
                <LeftFormRightImage
                  image={slackteam}
                  altImage={"Employee Offboarding chatbot"}
                >
                  <LeftFormRightImage.Header>
                    <h3>Offboarding chatbot on Slack and Microsoft Teams</h3>
                  </LeftFormRightImage.Header>
                  <LeftFormRightImage.SubHeader>
                    <p>
                      Workativ lets IT & HR Teams use Slack and Teams’ familiar
                      interface to converse with a chatbot to complete the
                      offboarding tasks instead of relying on email
                      communications. Create a chatbot for Offboarding
                      employees, without writing a line of code! Deploy the
                      chatbot on Slack or Microsoft Teams, and you can help HR &
                      IT save time offboarding employees.
                    </p>
                  </LeftFormRightImage.SubHeader>
                  <LeftFormRightImage.Link>
                    <div className="card_link_landing">
                      <SlackAndTeams />
                    </div>
                  </LeftFormRightImage.Link>
                </LeftFormRightImage>
              </section>
              <Slider
                title={"Other popular use cases"}
                sliderData={sliderData}
                id={"section_5"}
              />
            </div>

            <div className="popular_workflows_">
            <KeyResources cards={resoucesData} />
            </div>
          </section>
          {/* <GoToPopup /> */}
          <RequestForm isFooterForm={true} />
        </Layout>
      </Container>
    </>
  );
}

export const leave = require("../../assets/images/usecases/common/leave_management.png");
export const onboardinng = require("../../assets/images/usecases/common/onboardinng.png");
export const payroll = require("../../assets/images/usecases/common/payroll.png");
export const reset = require("../../assets/images/usecases/common/reset.png");
export const ticketing = require("../../assets/images/usecases/common/ticketing.png");
export const unlock = require("../../assets/images/usecases/common/unlock.png");
export const user_provision = require("../../assets/images/usecases/common/user_provision.png");

const sliderData = [
  {
    header: "Employee Onboarding",
    alt: "Employee Onboarding Automation",
    image: onboardinng,
    content:
      "Simplify the onboarding tasks for modern HR teams and deliver a positive onboarding experience.",
    link: "Learn more",
    url: "/use-cases/employee-onboarding-automation"
  },
  {
    header: "Unlock Account",
    alt: "Unlock Account Automation",
    image: unlock,
    content:
      "Strengthen enduser security and minimize calls to the helpdesk with ‘unlock account’ automation.",
    link: "Learn more",
    url: "/use-cases/unlock-account-automation"
  },
  {
    header: "Password Reset",
    alt: "Password Reset Automation",
    image: reset,
    content:
      "Expedite issue resolution for employees and reduce service desk call volume with ‘password reset’ automation.",
    link: "Learn more",
    url: "/use-cases/reset-password-automation"
  },

  {
    header: "Payroll Enquiry",
    alt: "Payroll Enquiry Automation",
    image: payroll,
    content:
      "Streamline automation for delivering payroll information to employees with zero waiting time.",
    link: "Learn more",
    url: "/use-cases/payroll-enquiry-automation"
  },
  {
    header: "AI Ticketing",
    alt: "AI Ticketing Automation",
    image: ticketing,
    content:
      "Reduce contact with the helpdesk for repetitive issues and transform the employee experience with AI.",
    link: "Learn more",
    url: "/use-cases/ai-ticketing"
  },
  {
    header: "User Provisioning",
    alt: "User Provisioning Automation",
    image: user_provision,
    content:
      "Reduce costs and improve employee experience with user provisioning automation.",
    link: "Learn more",
    url: "/use-cases/user-provisioning-automation"
  },
  {
    header: "Leave management",
    alt: "Leave management automation",
    image: leave,
    content:
      "Simplify PTO inquiries and requests for employees with an automated, intelligent and user-friendly chatbot.",
    link: "Learn more",
    url: "/use-cases/leave-management-automation"
  }
];

const bamboo = require("../../assets/images/icons-web/bamboo.svg");
const ms_office = require("../../assets/images/icons-web/ms_office.svg");
const freshservice = require("../../assets/images/icons-web/freshservice.svg");
const fd = require("../../assets/images/icons-web/fd_1.svg");
const zoho_people = require("../../assets/images/icons-web/zoho_people_1.svg");
const people_hr = require("../../assets/images/icons-web/people-hr.svg");
const purelyhr = require("../../assets/images/icons-web/purelyhr.svg");
const zohodesk = require("../../assets/images/icons-web/zohodesk.svg");

const appsName = [
  {
    image: bamboo,
    alt: "bamboo"
  },
  {
    image: ms_office,
    alt: "ms_office"
  },
  {
    image: freshservice,
    alt: "freshservice"
  },
  {
    image: zoho_people,
    alt: "Zoho People"
  },
  {
    image: fd,
    alt: "Freshdesk"
  },
  {
    image: people_hr,
    alt: "people_hr"
  },
  {
    image: purelyhr,
    alt: "people_hr"
  },
  {
    image: zohodesk,
    alt: "zohodesk"
  }
];

export const SlackAndTeams = () => (
  <div className="chat_channels_trial">
    <div className="chat_channels_trial_btn">
      <img loading = "lazy"
        src={require("../../assets/images/trial_landing/slack.png")}
        alt="slack"
      />
      Slack
    </div>
    <div className="chat_channels_trial_btn">
      <img loading = "lazy"
        src={require("../../assets/images/trial_landing/teams.png")}
        alt="teams"
      />
      Teams
    </div>
  </div>
);
